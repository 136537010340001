import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary ">
      <Container fluid>
      <img style={{height:"40px", width:"170px", margin:"12px 16px 12px 4rem"}} src='pchcom.png' alt="image" />

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/winner">Winner</Nav.Link>
          </Nav>
          <div className='d-flex'>
          <Nav.Link style={{marginRight:"30px"}} href="/login">SignIn</Nav.Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;