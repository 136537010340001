import React, { useEffect, useState } from "react";

const winnerData = [
  { id: 1, price: 366664,img:'./person.jpeg', name:"Danniel", area:"Sant Joseph", rank:"first" },
  { id: 2, price: 366664,img:'./person.jpeg', name:"Danniel", area:"Sant Joseph",rank:"second" },
  { id: 3, price: 366664,img:'./person.jpeg', name:"Danniel", area:"Sant Joseph", rank:"third" }
];

const Winner = () => {

  const [loading , setLoading] = useState(true)
  const [winnerData1, setWinnerData] = useState([])
  
  const fetchWinnerData = async() =>{
    setLoading(true)
    const res = await fetch(`https://shopify.innovationpixel.com/public/get_winners_psu`)
    const data = res.json()
    console.log(data, res);
    
    setWinnerData(data)
    setLoading(false)
  }

  useEffect(()=>{
    fetchWinnerData()
  },[])

  // if(loading){
  //   return <div style={{fontSize:"20px", margin:"auto", marginTop:"2rem", marginBottom:"2rem"}}>loading...</div>
  // }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/PCHlogo.png"
        alt="pchlogo"
        style={{ width: "150px", height: "20px", marginTop: "80px  " }}
      />
      <img
        src="/winners2.png"
        alt="winner"
        style={{ width: "200px", height: "50px" }}
      />
      <h1
        style={{
          fontSize: "32px",
          fontWeight: "bolder",
          textAlign: "center",
          marginTop: "1rem",
         }}
      >
        Most Recent Winners
      </h1>
      <h1
        style={{
          fontSize: "22px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        Most Recent Winners
      </h1>

      <div style={{width:"100%",display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>{winnerData.map((item,_)=>(
       <div style={{width:item.rank === "first" ? "60%": "40%"}} key={item.id}>
       <img src={item.img} alt={item.name} style={{width:'100%',height:"250px"}}/>
        <h5 style={{ color:"#00A7FF",  fontSize: item.rank === 'first' ? '20px' : '16px' }}>
         {item.name}
       </h5>
       <h5 style={{ color:"black",  fontSize: item.rank === 'first' ? '18px' : '14px' }}>{item.price}</h5>
       <h5 style={{ color:"black",  fontSize: item.rank === 'first' ? '18px' : '14px' }}>{item.area}</h5>
     </div>
     
      ))}</div>
    </div>
  );
};

export default Winner;
