import React from "react";
import Hero from '../pages/Hero'
// import Prices from '../pages/Prices'
import Reward from '../pages/Reward'
import Winner from "./Winner";
const Home = () => {
  return (
    <>
      <Hero />
      {/* <Prices /> */}
      <Winner/>
      <Reward />
    </>
  );
};

export default Home;
