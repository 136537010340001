import React, { useState } from 'react';
import Email from './Email';

// Months, Years, and Days Arrays
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946, 1945, 1944, 1943, 1942, 1941, 1940, 1939, 1938, 1937, 1936, 1935, 1934, 1933, 1932, 1931, 1930, 1929, 1928, 1927, 1926, 1925, 1924, 1923, 1922, 1921, 1920, 1919, 1918, 1917, 1916, 1915, 1914, 1913, 1912, 1911, 1910, 1909, 1908, 1907, 1906, 1905, 1904];
let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

function Login() {
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    apt: '',
    city: '',
    state: '',
    zipCode: '',
    month: '',
    day: '',
    year: '',
    password: '',
    verifyPassword: '',
    subscribe: false,
  });

  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();

    console.log(formData);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      alert("Email cannot be empty");
    } else if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
    } else {
      setEmailVerified(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    for (const key in formData) {
      if (key !== 'apt' && formData[key] === '') {
        newErrors[key] = `${key} is required`;
      }
    }

   
    if (formData.password !== formData.verifyPassword) {
      newErrors.passwordMatch = "Passwords do not match!";
    }

   
    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    // if (formData.password && !passwordRegex.test(formData.password)) {
    //   console.log(formData.password);
      
    //   newErrors.passwordStrength = "Password must be at least 8 characters long and contain both letters and numbers.";
    // }
    if (!formData.month || !formData.day || !formData.year) {
      newErrors.dateOfBirth = "Please select your birth date.";
    }

    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await fetch('https://shopify.innovationpixel.com/public/signup_psu', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            ...formData,
            birthDate: `${formData.month} ${formData.day}, ${formData.year}`,
          }),
        });       
        const result = await response.json();

        if (response.ok) {
          setMessage('Registration successful!');
        } else {
          setMessage(result.message || 'Something went wrong. Please try again.');
        }
      } catch (error) {
        setMessage('Error: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!emailVerified ? (
        <Email handleSubmitEmail={handleSubmitEmail} email={email} handleEmailChange={handleEmailChange} />
      ) : (
        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
            <h2 style={{ textAlign: 'center', marginTop: "16px" }}>Complete Your Registration</h2>

            {/* Form Fields */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', marginTop: "50px" }}>
              <select
                name="title"
                value={formData.title}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              >
                <option value="">Title</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
              </select>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
            </div>

            {/* Additional Fields */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <input
                type="text"
                name="street"
                placeholder="Street"
                value={formData.street}
                onChange={handleChange}
                style={{ width: '70%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
              <input
                type="text"
                name="apt"
                placeholder="Apt, Etc"
                value={formData.apt}
                onChange={handleChange}
                style={{ width: '30%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
              />
            </div>

            {/* City, State, Zip Code */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
                style={{ width: '45%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
                style={{ width: '30%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
              <input
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                value={formData.zipCode}
                onChange={handleChange}
                style={{ width: '22%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              />
            </div>

            {/* Date of Birth (Month, Day, Year) */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <select
                name="month"
                value={formData.month}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              >
                <option value="">Month</option>
                {months.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              <select
                name="day"
                value={formData.day}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              >
                <option value="">Day</option>
                {days.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              <select
                name="year"
                value={formData.year}
                onChange={handleChange}
                style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                required
              >
                <option value="">Year</option>
                {years.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>

            {/* Password and Verify Password */}
            <input
              type="password"
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }}
              required
            />
            <input
              type="password"
              name="verifyPassword"
              placeholder="Verify Password"
              value={formData.verifyPassword}
              onChange={handleChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }}
              required
            />

            {errors.passwordMatch && <div style={{ color: 'red' }}>{errors.passwordMatch}</div>}
            {errors.passwordStrength && <div style={{ color: 'red' }}>{errors.passwordStrength}</div>}
            {errors.dateOfBirth && <div style={{ color: 'red' }}>{errors.dateOfBirth}</div>}

            {/* Subscribe */}
            <label style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <input
                type="checkbox"
                name="subscribe"
                checked={formData.subscribe}
                onChange={handleChange}
                style={{ marginRight: '10px' }}
              />
              Yes! I'd like to be informed about chances to win and offers from pch.com via email.
            </label>

            {/* Submit Button */}
            <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
              Submit
            </button>

            {loading && <p>Submitting...</p>}
            {message && <p>{message}</p>}

            <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '10px' }}>
              By continuing, I agree to PCH's <a href="#" style={{ color: '#007bff' }}>Privacy Policy</a> and <a href="#" style={{ color: '#007bff' }}>Data Sharing Practices</a>.
            </p>
          </form>
        </div>
      )}
    </>
  );
}

export default Login;
