import React from 'react';
const Email = ({handleSubmitEmail,email,handleEmailChange}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f9f9f9'
    }}>
      <div style={{
        width: '500px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        backgroundColor: '#fff',
      }}>
        <div style={{
          backgroundImage: 'url("/emailVerified.png")',
          backgroundSize: 'cover',
          height: '300px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}></div>

        <h2 style={{ fontSize: '18px', fontWeight: 'bold', margin: '20px 0' }}>
          LET'S GET YOU IN ON THE WINNING!
        </h2>
        <p style={{ color: '#777', marginBottom: '20px' }}>
          Sign up or sign in now - it's easy!
        </p>

        <form onSubmit={handleSubmitEmail} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding:"12px"}}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email Address"
            required
            style={{
              width: '100%',
              padding: '10px',
              marginBottom:"10px",
              border: '1px solid #ddd',
              borderRadius: '5px',
            }}
          />
<p style={{ fontSize: '12px', color: '#888', marginTop: '20px' }}>
          By continuing, I agree to PCH's{' '}
          <a href="https://example.com/privacy-policy" style={{ color: '#0073e6', textDecoration: 'none' }}>
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href="https://example.com/sharing-practices" style={{ color: '#0073e6', textDecoration: 'none' }}>
            Sharing Practices
          </a>.
        </p>
          <button type="submit" style={{
            width: '70%',
            padding: '10px',
            backgroundColor: '#ccc',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            fontWeight: 'bold',
            cursor: 'pointer',
            borderRadius:"26px"
          }}>
            CONTINUE
          </button>
        </form>

        
      </div>
    </div>
  );
};

export default Email;
