import React from 'react';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <div style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '20px', fontFamily: 'Arial, sans-serif', marginTop:"8px" }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{display:'flex', flexDirection:"row", justifyContent:"space-around",width:'100%'}}>
        <img style={{height:"80px", width:"350px", margin:"12px 16px 12px 0px"}} src='footerlogo.webp' alt="image" />
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', gap: '15px', fontSize: '20px' }}>
          <h1 style={{ textAlign:"center", fontSize:"28px", fontWeight:'bolder' }}>LET'S CONNECT</h1>
          <div style={{display:"flex", flexDirection:'row', justifyContent:'center', gap:"20px"}}>
          <span style={{ cursor: 'pointer', backgroundColor:"#FFA733", color:'black', padding:"6px 6px", borderRadius:"50%"}}><FaFacebook style={{fontSize:"28px"}} />
          </span>
          <span style={{ cursor: 'pointer', backgroundColor:"#FFA733", color:'black', padding:"6px 6px", borderRadius:"50%"}}><FaInstagram style={{fontSize:"28px"}}/>
          </span>
          <span style={{ cursor: 'pointer', backgroundColor:"#FFA733", color:'black', padding:"6px 6px", borderRadius:"50%"}}><FaYoutube style={{fontSize:"28px"}}/>
          </span>
          <span style={{ cursor: 'pointer', backgroundColor:"#FFA733", color:'black', padding:"6px 6px", borderRadius:"50%"}}><BsTwitterX style={{fontSize:"28px"}}/>
          </span>
          <span style={{ cursor: 'pointer', backgroundColor:"#FFA733", color:'black', padding:"6px 6px", borderRadius:"50%"}}><FaTiktok style={{fontSize:"28px"}}/>
          </span>
          </div>
        </div>
        </div>
       
        {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: '30px' }}>
          <span style={{ cursor: 'pointer' }}>Other Channels</span>
          <span style={{ cursor: 'pointer' }}>Customer Care Center</span>
          <span style={{ cursor: 'pointer' }}>Media Center</span>
        </div> */}
        
       
        
        <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: '10px' }}>
          <img src="path/to/truste-logo.png" alt="TRUSTe Certified Privacy" style={{ height: '40px' }} />
          <img src="path/to/iab-logo.png" alt="IAB Compliance" style={{ height: '40px' }} />
          <img src="path/to/bbb-logo.png" alt="BBB Accredited Business" style={{ height: '40px' }} />
        </div>
        
        <div style={{ fontSize: '12px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
          <p>NO PURCHASE OR FEE NECESSARY TO ENTER. A PURCHASE WON'T IMPROVE AN INDIVIDUAL'S CHANCE OF WINNING.</p>
          <p>&copy; Copyright 2024 PCH.com. All Rights Reserved.</p>
          <p>All trademarks and registered trademarks appearing on this site are the property of their respective owners. Said owners do not endorse nor are they affiliated with pch.com or its promotions.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
