import Button from "react-bootstrap/Button";
import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const handleWinnerPage = () => {
    navigate("/winner");
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "80vh",
          overflow: "hidden",
        }}
      >
        {/* Background image */}
        <img
          src="hero.webp"
          alt="Hero Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
        {/* Linear gradient overlay with pointer-events disabled */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0.1, 0.1), rgba(0, 0, 0.1, 1))`,
            pointerEvents: "none", // Prevents this overlay from blocking clicks
          }}
        ></div>
        
        {/* Text and Button container */}
        <div
          style={{
            position: "relative",
            color: "white",
            textAlign: "center",
            zIndex: 1,
            padding: "20px",
          }}
        >
          <h2 style={{ marginTop: "70px" }}>
            Welcome To Publishers Clearing House - Where America Plays To <br />{" "}
            Win
          </h2>
          <hr
            style={{
              background: "white",
              color: "white",
              height: "2px",
              margin: "auto",
              width: "90%",
            }}
          />
          <h1 style={{ fontSize: "60px", fontWeight: "bold" }}>
            Win A $1,000,000
          </h1>
          <h3 style={{ fontWeight: "bold" }}>Set For Life Prize</h3>

          <Button
            variant="primary"
            style={{
              paddingLeft: "100px",
              paddingRight: "100px",
              paddingTop: "12px",
              paddingBottom: "12px",
              borderRadius: "22px",
              border: "2px solid white",
              marginTop: "30px",
              fontSize: "18px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleWinnerPage}
          >
            LET'S GO
          </Button>
        </div>
      </div>
      <div style={{ boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.3)" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-40px",
            zIndex: 1,
          }}
        >
          <h3
            style={{
              backgroundColor: "black",
              padding: "8px 28px 8px 16px",
              borderRadius: "20px",
              fontSize: "22px",
              color: "white",
            }}
          >
            WINNER ANNOUNCED ON
          </h3>
          <img
            src="tvlogos.png"
            alt="TV Logos"
            style={{ height: "90px", width: "250px", marginLeft: "-22px" }}
          />
        </div>
        <Container>
          <p
            style={{
              fontSize: "22px",
              fontWeight: "500",
              color: "black",
              padding: "10px 10px 10px 10px",
            }}
          >
            For Over 50 years, Publishers Clearing House has been giving away
            real prizes, all across the country absolutely free because we love
            making dreams come true! There's no gimmick, no catch, just free
            money waiting to be given away in our online sweepstakes to good
            people like you. So, go ahead, enter to win your favorite prizes
            today and we could be making you a PCH winner in no time.
          </p>
          <hr />
          <div style={{ paddingBottom: "40px" }}>
            <h1
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "black",
                textAlign: "center",
              }}
            >
              Find out about why PCH gives away all this money
            </h1>
            <hr
              style={{
                backgroundColor: "black",
                marginBottom: "12px",
                margin: "auto",
                height: "2px",
                width: "50%",
                opacity: 1,
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Hero;
