import React from "react";
const rewards = [
  { icon: '1.svg', text: "$615M+", desc: "in prizes awards by pch" },
  { icon: '2.svg', text: "$23M+", desc: "In Prizes Awarded This Year" },
  { icon: '3.svg', text: "11M+", desc: "Prizes Awarded To Date" },
  { icon: '4.svg', text: "150+", desc: "Millionaire-Making Prizes Awarded" }
];
const Reward = () => {
  return <div style={{backgroundColor:"#FFA733", color:'black'}}>
    <h1 style={{fontSize:"22px", textAlign:'center', paddingTop:'26px', paddingBottom:'16px', marginTop:'16px'}}>OVER $615 MILLION IN PRIZES AWARDED SINCE 1967, AND YOU COULD BE THE NEXT WINNER!</h1>
    <div style={{display:"flex", justifyContent:'center', marginTop:'30px'}}>
        {rewards.map((item,index)=>(
            <div key={index} style={{display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center", paddingLeft:'20px', paddingRight:'20px', marginBottom:"20px"}}>
                <img src={item.icon} alt={item.text} style={{width:"40px", height:'40px'}}/>
                <h1 style={{fontWeight:"bolder"}}>{item.text}</h1>
                <h5 style={{fontSize:"16px"}}>{item.desc}</h5>
            </div>
        ))}
    </div>
  </div>;
};

export default Reward;
